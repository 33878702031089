import React from 'react'
import logo from "./assets/logo-light.png"
import "./style.css"

export default function App() {
  return (
    <div className='main'>
      <div className='logo-block'>
        <img src={logo} alt="/" />
        <small>Coming soon...</small>
      </div>
    </div>
  )
}
